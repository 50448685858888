<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="换绑数据"
        width="1200"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <div class="content-wrap">
            <div class="selectable-title">数据中心({{ total }})
                <Input v-model="query" class="float-right margin-right" clearable placeholder="输入数据名称、条码" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                    <Icon type="ios-search" slot="suffix" @click="searchNew" />
                </Input>
            </div>
            <div class="section">
                <no-data v-show="list.length === 0" text="暂无数据"></no-data>
                <CheckboxGroup
                    v-show="list.length > 0"
                    class="content"
                    v-model="checkAllGroup"
                    @on-change="checkAllGroupChange">
                    <div
                        v-for="item in list"
                        :key="item.rowid"
                        class="content-item pointer"
                        @click="handleSelect(item)">
                        <div class="column-wrap">
                            <div class="left-section">
                                <div class="img-wrap">
                                    <img class="image-center" :src="`/cym/${item.data.banner_picture?item.data.banner_picture.url:''}`">
                                </div>
                            </div>
                            <div class="right-section">
                                <div class="name-price">
                                    <div class="name line-overflow">{{ item.data.name }}</div>
                                    <div class="price line-overflow">¥&nbsp;{{ item.data.price }}</div>
                                </div>
                                <div class="code line-overflow">{{ item.data.code }}</div>
                            </div>
                        </div>
                        <Checkbox class="checkbox" :label="item.rowid" @click.native.stop="handleCheckbox(item)"><span>&nbsp;</span></Checkbox>
                    </div>
                </CheckboxGroup>
                <Spin fix size="large" v-if="loading"></Spin>
            </div>
            <Page
                class="page"
                :total="total"
                :current="current"
                :page-size="pageSize"
                show-elevator
                @on-change="nextPage" />
            <div class="selected-title">已绑数据({{ list2.length }})</div>
            <div class="section">
                <no-data v-show="pagesList.length === 0" text="暂无绑定数据"></no-data>
                <div v-show="pagesList.length > 0" class="content">
                    <div
                        v-for="item in list2"
                        :key="item.rowid"
                        class="content-item pointer">
                        <div class="column-wrap">
                            <div class="left-section">
                                <div class="img-wrap">
                                    <img class="image-center" :src="`/cym/${item.data.banner_picture?item.data.banner_picture.url:''}`">
                                </div>
                            </div>
                            <div class="right-section">
                                <div class="name-price">
                                    <div class="name line-overflow">{{ item.data.name }}</div>
                                    <div class="price line-overflow">¥&nbsp;{{ item.data.price }}</div>
                                </div>
                                <div class="code line-overflow">{{ item.data.code }}</div>
                                <div class="remove-wrap">
                                    <i class="iconfont icon-lajitong icon-remove pointer" @click="handleRemove(item.rowid)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Page
                class="page"
                :total="total2"
                :current="current2"
                :page-size="pageSize"
                show-elevator
                @on-change="nextPage2" />
        </div>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">取消</Button>
            <Button type="primary" :disabled="loading2" :loaing="loading3" @click="submit">确认换绑</Button>
        </div>
    </Modal> 
</template>
<script>
    import noData from '@/views/components/noData'
    export default {
        name: "",
        mixins: [],
        components: {
            noData
        },
        props: {
            show: {
                default: false
            },
            terminalId: {
                default: null
            }
        },
        data () {
            return {
                modal: false,
                query: '',
                total: 0,
                loading: false,
                current: 1,
                pageSize: 9,
                list: [],
                total2: 0,
                loading2: false,
                current2: 1,
                list2: [],
                pagesList: [],
                checkAllGroup: [],
                indeterminate: false,
                checkAll: false,
                itemInfo: {},
                loading3: false
            }
        },
        computed: {
            bucket_token() {
                let bucket_token = ''
                if (this.$store.state.default_bucket_tokens.org) {
                    bucket_token = this.$store.state.default_bucket_tokens.org
                } else {
                    bucket_token = this.$store.state.default_bucket_tokens.user
                }
                return bucket_token
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.getData()
                    let params = {
                        bucket_token: this.bucket_token,
                        terminal_id: this.terminalId,
                        start: 0,
                        count: -1
                    }
                    this.loading2 = true
                    this.$api.get('commodities/search_with_bind_terminal',  { params }).then(({ data }) => {
                        this.pagesList = data
                        this.nextPage2(this.current2)
                    }).catch(() => {}).finally(() => {
                        this.loading2 = false
                    })
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.query = ''
                this.list = []
                this.list2 = []
                this.pagesList = []
                this.checkAllGroup = []
                this.$emit('cancel')
            },
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.getData()
            },
            handleCheckAll() {
                if (this.indeterminate) {
                    this.checkAll = false;
                } else {
                    this.checkAll = !this.checkAll;
                }
                this.indeterminate = false;

                if (this.checkAll) {
                    //全选当前页
                    let set = new Set(this.checkAllGroup)
                    this.list.map((item)=> {
                        if (!set.has(item.rowid)) {
                            set.add(item.rowid)
                            this.pagesList.push(item)
                            //重新获取组内当前页的数据
                            this.nextPage2(this.current2)
                        }
                    })
                    this.checkAllGroup = Array.from(set)
                } else {
                    let len = this.list.length
                    for (let i = 0; i < len; i++) {
                        let index = this.checkAllGroup.indexOf(this.list[i].rowid)
                        this.checkAllGroup.splice(index, 1)
                        for (let j = 0; j < this.pagesList.length; j++) {
                            if (this.list[i].rowid === this.pagesList[j].rowid) {
                                this.pagesList.splice(j, 1)
                                break
                            }
                        }
                    }
                    //重新获取组内当前页的数据
                    this.nextPage2(this.current2)
                }
            },
            checkAllGroupChange(e) {
                if (e.length > 0) {
                    this.indeterminate = true;
                    this.checkAll = false;
                } else {
                    this.indeterminate = false;
                    this.checkAll = false;
                }
                if (e.indexOf(this.itemInfo.rowid) === -1) {
                    //如果未选中，组内查找删除
                    this.pagesList.map((item, index) => {
                        if (this.itemInfo.rowid === item.rowid) {
                            this.pagesList.splice(index, 1)
                        }
                    })
                } else {
                    //若选中，添加到组内
                    this.pagesList.push(this.itemInfo)
                    let isAll = true
                    this.list.map((item)=>{
                        if (e.indexOf(item.rowid) === -1) {
                            isAll = false
                        }
                    })
                    this.checkAllGroup = e
                    //当前页全勾选，显示全选状态
                    if (isAll) {
                        this.indeterminate = false;
                        this.checkAll = true;
                    }
                }
                //重新获取组内当前页的数据
                this.nextPage2(this.current2)
            },
            handleCheckbox(item) {
                this.itemInfo = item
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    bucket_token: this.bucket_token,
                    terminal_id: this.terminalId,
                    query: this.query,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize,
                }
                this.loading = true
                this.checkAll = false
                this.$api.get('commodities/search_without_bind_terminal', { params }).then(({ data, total_count, page }) => {
                    if (page === this.current) {
                        let isAll = true
                        //检测是否当页全选，当前页全勾选，显示全选状态
                        data.map((item)=>{
                            if (this.checkAllGroup.indexOf(item.rowid) === -1 || this.checkAllGroup.length === 0) {
                                isAll = false
                            }
                        })
                        if (isAll) {
                            this.indeterminate = false
                            this.checkAll = true
                        }
                        this.list = data
                        this.total = total_count
                    }
                }).catch(() => {}).finally(() => {
                    this.loading = false
                })
            },
            nextPage2(e) {
                this.current2 = e
                let start = (this.current2 - 1) * this.pageSize,
                    end = start + this.pageSize;
                this.getData2(start, end)
            },
            getData2(start, end) {
                this.list2 = this.pagesList.slice(start, end)
            },
            handleRemove(rowid) {
                this.$Modal.confirm({
                    title: '提示',
                    content: '确认删除吗？',
                    closable: true,
                    onOk: () => {
                        let len = this.pagesList.length
                        for (let i = 0; i < len; i++) {
                            if (rowid === this.pagesList[i].rowid) {
                                this.pagesList.splice(i, 1)
                                break;
                            }
                        }
                        if(this.pagesList.length === (this.current2 - 1) * this.pageSize && this.current2 > 1) {
                            this.current2 -= 1
                        }
                        //重新获取组内当前页的数据
                        this.nextPage2(this.current2)
                        //处理选中状态
                        this.checkAllGroup.map((item, index) => {
                            if (item === rowid) {
                                this.checkAllGroup.splice(index, 1)
                            }
                        })
                        if (this.checkAllGroup.length == 0) {
                            this.indeterminate = false
                        }
                        this.checkAll = false
                    }
                })
            },
            submit() {
                let rowids = []
                this.pagesList.map((item) => {
                    rowids.push(item.rowid)
                })
                this.loading3 = true
                this.$api.get(`terminals/${this.terminalId}/commodities/data_num`).then(({data}) => {
                    if (data.data_num !== rowids.length) {
                        this.$Modal.info({
                            title: '提示',
                            content: `设备的模板商品数是${data.data_num}，您只能选${data.data_num}个商品`,
                            okText: '好的',
                            onOk: () => {
                                this.loading3 = false
                            }
                        });
                    } else {
                        this.$api.put(`terminals/${this.terminalId}/commodities`, { commodities: rowids }).then(() => {
                            this.$emit('confirm')
                            this.cancel()
                        }).catch(() => {}).finally(() => {
                            this.loading3 = false
                        })
                    }
                }).catch(() => {
                    this.loading3 = false
                })
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.content-wrap {
    overflow-x: hidden;
    position: relative;
    .selectable-title {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 700;
    }
    .selected-title {
        margin-bottom: 40px;
        font-size: 16px;
        font-weight: 700;
    }
    .section {
        position: relative;
        .content {
            width: 1176px;
            .content-item {
                position: relative;
                display: inline-block;
                margin: 0 5px 14px 0;
                width: 386px;
                height: 206px;
                border: 1px solid #e8eaec;
                .column-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 100%;
                    .left-section {
                        position: relative;
                        display: inline-block;
                        width: 206px;
                        height: 100%;
                        .img-wrap {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 181px;
                            height: 181px;
                            transform: translate(-50%, -50%);
                            background: #fff;
                        }
                    }
                    .right-section {
                        position: relative;
                        display: inline-block;
                        vertical-align: top;
                        width: 178px;
                        height: 100%;
                        .name-price {
                            position: absolute;
                            top: 50%;
                            left: 27px;
                            transform: translateY(-50%);
                            width: calc(100% - 47px);
                            .name {
                                margin-bottom: 12px;
                                font-size: 20px;
                                line-height: 24px;
                            }
                            .price {
                                font-size: 18px;
                                line-height: 22px;
                                color: #bc6347;
                            }
                        }
                        .code {
                            position: absolute;
                            right: 20px;
                            bottom: 13px;
                            width: calc(100% - 47px);
                            font-size: 16px;
                            line-height: 20px;
                            text-align: right;
                        }
                        .order {
                            position: absolute;
                            top: 20px;
                            right: 20px;
                            width: 21px;
                            height: 21px;
                            background: #999;
                            border-radius: 50%;
                            color: #fff;
                            font-size: 14px;
                            line-height: 21px;
                            text-align: center;
                        }
                        .remove-wrap {
                            position: absolute;
                            right: 16px;
                            top: 16px;
                            width: 34px;
                            height: 34px;
                            background: rgba(0,0,0,0.4);
                            border-radius: 50%;
                            &:hover {
                                background: rgba(0,0,0,0.6);
                            }
                            .icon-remove {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                color: #f8fbff;
                            }
                        }
                    }
                }
                .checkbox {
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin-right: 0;
                    padding: 10px 0 0 18px;
                    width: 100%;
                    height: 204px;
                }
            }
        }
    }
}
</style>