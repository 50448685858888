<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="中继转移"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <p class="tip">该操作会把中继下面的墨水屏全部转移到对应的组织节点（门店）</p>
        <Form class="formValidate" ref="formValidate" :model="formValidate" :rules="ruleValidate"  @submit.native.prevent>
            <FormItem label="" prop="department_id" key="department_id">
                <Select ref="department" v-model="formValidate.department_id" style="width:200px" transfer>
                    <Option style="display: none;" :value="departmentInfo.department_id">{{ departmentInfo.title }}</Option>
                    <Tree :data="data" @on-select-change="handleTree($event, 'department')" ></Tree>
                </Select>
            </FormItem>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">确定</Button>
        </div>
    </Modal> 
</template>
<script>
    import { tree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [tree],
        components: {},
        props: {
            rowids: {
                default: () => {
                    return []
                }
            },
            show: {
                default: false
            }
        },
        data () {
            return {
                modal: false,
                data: [],
                formValidate: {
                    department_id: null
                },
                ruleValidate: {
                    department_id: [
                        {
                            required: true, type: 'number', message: '请选择', trigger: 'blur' 
                        }
                    ]
                },
                loading: false,
                departmentInfo: {
                    department_id: '',
                    title: ''
                }
            }
        },
        computed: {
            tree_data() {
                return [this.$store.state.department_tree]
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.data = this.handleRecursive(this.tree_data, null)
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.departmentInfo =  {
                    department_id: '',
                    title: ''
                }
                this.$refs.formValidate.resetFields()
                this.$emit('cancel')
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        let params = {
                            rowid_list: this.rowids,
                            department_id: this.formValidate.department_id
                        }
                        this.loading = true
                        this.$api.put('repeaters/transfer', params).then(() => {
                            this.$emit('confirm')
                            this.cancel()
                        }).catch(() => {}).finally(() => {
                            this.loading = false
                        })
                    }
                })
            }
        },
        created() {}
    }
</script>
<style scoped>
    
</style>